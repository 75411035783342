import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTheme } from '../ThemeContext';
import { useStory } from '../StoryContext';
import '../css/style.scss';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Modal from './Modal';
import ImageUploader from './ImageUploader';
import ImagePreview from './ImagePreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import facebookLogo from '../img/facebook_logo.webp';
import instagramLogo from '../img/instagram_logo.webp';
import linkedinLogo from '../img/linkedin_logo.webp';
import telegramLogo from '../img/telegram_logo.svg';

function Project() {
  const API_URL = process.env.REACT_APP_API_URL;
  const CHAT_KEY = process.env.REACT_APP_CHAT_KEY;
  const { projectId, setProjectId, projectName, setProjectName, niche, setNiche, audience, setAudience, audienceCharacteristics, setAudienceCharacteristics, contentGoal, setContentGoal, textTone, setTextTone, productService, setProductService, textCustomisation, setTextCustomisation, ideas, setIdeas, selectedLanguage, setSelectedLanguage } = useTheme();

  const { storyId, setStoryId, storyGoal, setStoryGoal, storyTheme, setStoryTheme, storyCharacter, setStoryCharacter, storyProblem, setStoryProblem, storySolution, setStorySolution, storyResult, setStoryResult, storyEmotions, setStoryEmotions, storyMoments, setStoryMoments, storyCta, setStoryCta, storyQty, setStoryQty, selectedStoryLanguage, setSelectedStoryLanguage } = useStory();

  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const resultsRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { user, logout } = useAuth();
  const [projects, setProjects] = useState([]);
  const [fetchedThemes, setFetchedThemes] = useState([]);
  const [fetchedPostThemes, setFetchedPostThemes] = useState([]);
  const [fetchedReelThemes, setFetchedReelThemes] = useState([]);
  const [fetcheStoriesThemes, setFetcheStoriesThemes] = useState([]);
  const [fetchedProfiles, setFetchedProfiles] = useState([]);
  const [fetchedAnalyses, setFetchedAnalyses] = useState([]);
  const [fetchedStories, setFetchedStories] = useState([]);

  const location = useLocation(); 

  const { selectedProjectId, stateActiveTab: stateActiveTab } = location.state || {};
  const [activeProjectId, setActiveProjectId] = useState(selectedProjectId || null);

  const query = new URLSearchParams(location.search);
  const tabFromUrl = query.get('tab');

  const [activeTab, setActiveTab] = useState(() => {
    if (tabFromUrl) {
      return tabFromUrl;
    } else if (stateActiveTab) {
      return stateActiveTab;
    } else {
      return 'ideas';
    }
  });

  const [error, setError] = useState('');
  const [isProjectVisible, setIsProjectVisible] = useState(false);
  const [isThemesVisible, setIsThemesVisible] = useState(false);
  const [isPostsVisible, setIsPostsVisible] = useState(false);
  const [isReelsVisible, setIsReelsVisible] = useState(false);
  const [isStoriesVisible, setIsStoriesVisible] = useState(false);
  const [isProfilesVisible, setIsProfilesVisible] = useState(false);
  const [isAnalysesVisible, setIsAnalysesVisible] = useState(false);
  const [userCanCreateProject, setUserCanCreateProject] = useState(false);
  const [userCanGenerateTheme, setuserCanGenerateTheme] = useState(false);
  const [userCanGeneratePost, setuserCanGeneratePost] = useState(false);
  const [userCanGenerateReel, setuserCanGenerateReel] = useState(false);
  const [userCanGenerateStory, setuserCanGenerateStory] = useState(false);
  const [userOnPaidPlan, setuserOnPaidPlan] = useState(false);

  const [userCanGenerateProfile, setuserCanGenerateProfile] = useState(false);
  const [userCanAnalysisProfile, setuserCanAnalysisProfile] = useState(false);
  const [userCanCustomTheme, setuserCanCustomTheme] = useState(false);

  const [loadingField, setLoadingField] = useState(null);
  const [analysisResult, setAnalysisResult] = useState('');
  const [analysisImg, setAnalysisImg] = useState('');
  const [savedData, setSavedData] = useState(null);
  const analysisRef = useRef(null);
  const [profileResult, setProfileResult] = useState('');
  const profileRef = useRef(null);

  const [reelFormat, setReelFormat] = useState('');
  const [reelCta, setReelCta] = useState('');

  const [themeInput, setThemeInput] = useState('');
  const [postLanguage, setPostLanguage] = useState(selectedLanguage || '');
  const [themeInputError, setThemeInputError] = useState(false);

  const token = localStorage.getItem('token');

  const [validationError, setValidationError] = useState('');

  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState(false);
  const [isGenerateThemesModalOpen, setIsGenerateThemesModalOpen] = useState(false);
  const [isNoMorePostsModalOpen, setIsNoMorePostsModalOpen] = useState(false);
  const [isNoMoreScenariosModalOpen, setIsNoMoreScenariosModalOpen] = useState(false);
  const [isGenerateProfilesModalOpen, setIsGenerateProfilesModalOpen] = useState(false);
  const [isGenerateStoryModalOpen, setIsGenerateStoryModalOpen] = useState(false);
  const [isAnalyseProfileModalOpen, setIsAnalyseProfileModalOpen] = useState(false);
  const [isCustomThemeModalOpen, setIsCustomThemeModalOpen] = useState(false);
  const [isCustomScenarioThemeModalOpen, setIsCustomScenarioThemeModalOpen] = useState(false);

  const openCreateProjectModal = () => {
    closeAllModals();
    setIsCreateProjectModalOpen(true)
  };
  const closeCreateProjectModal = () => setIsCreateProjectModalOpen(false);

  const openGenerateThemesModal = () => {
    closeAllModals();
    setIsGenerateThemesModalOpen(true)
  };
  const closeGenerateThemesModal = () => setIsGenerateThemesModalOpen(false);

  const openNoMorePostsModal = () => {
    closeAllModals();
    setIsNoMorePostsModalOpen(true)
  };
  const closeNoMorePostsModal = () => setIsNoMorePostsModalOpen(false);

  const openNoMoreScenariosModal = () => {
    closeAllModals();
    setIsNoMoreScenariosModalOpen(true)
  };
  const closeNoMoreScenariosModal = () => setIsNoMoreScenariosModalOpen(false);

  const openGenerateProfilesModal = () => {
    closeAllModals();
    setIsGenerateProfilesModalOpen(true)
  };
  const closeGenerateProfilesModal = () => setIsGenerateProfilesModalOpen(false);

  const openCustomThemeModal = () => {
    closeAllModals();
    setIsCustomThemeModalOpen(true)
  };
  const closeCustomThemeModal = () => setIsCustomThemeModalOpen(false);

  const openCustomScenarioThemeModal = () => {
    closeAllModals();
    setIsCustomScenarioThemeModalOpen(true)
  };
  const closeCustomScenarioThemeModal = () => setIsCustomScenarioThemeModalOpen(false);

  const openAnalyseProfileModal = () => {
    closeAllModals();
    setIsAnalyseProfileModalOpen(true)
  };
  const closeAnalyseProfileModal = () => setIsAnalyseProfileModalOpen(false);

  const openGenerateStoryModal = () => {
    closeAllModals();
    setIsGenerateStoryModalOpen(true)
  };
  const closeGenerateStoryModal = () => setIsGenerateStoryModalOpen(false);

  const closeAllModals = () => {
    setIsCreateProjectModalOpen(false);
    setIsGenerateThemesModalOpen(false);
    setIsGenerateProfilesModalOpen(false);
    setIsGenerateStoryModalOpen(false);
    setIsCustomThemeModalOpen(false);
    setIsCustomScenarioThemeModalOpen(false);
    setIsAnalyseProfileModalOpen(false);
    setIsIdeaModalOpen(false);
    setIsNoMorePostsModalOpen(false);
    setIsNoMoreScenariosModalOpen(false);
  };

  const handleGoToBilling = () => {
    closeAllModals();
    navigate('/billing');
  };

  const isAdmin = user?.admin;

  const [saveMessage, setSaveMessage] = useState('');

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(`${API_URL}/projects`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        setProjects(response.data.projects);
        setUserCanCreateProject(response.data.can_create_project); 
        setuserCanGenerateTheme(response.data.can_generate_theme); 
        setuserCanGeneratePost(response.data.can_generate_post); 
        setuserCanGenerateReel(response.data.can_generate_reel);
        setuserCanGenerateProfile(response.data.can_generate_profile);
        setuserCanAnalysisProfile(response.data.can_analysis_profile);
        setuserCanGenerateStory(response.data.can_generate_story);
        setuserOnPaidPlan(response.data.on_paid_plan); 

        if (selectedProjectId) {
          const selectedProject = response.data.projects.find(project => project.id === selectedProjectId);
          if (selectedProject) {
            handleSelectProject(selectedProject); 
          }
        } else {
          const project = response.data.projects[0];
          if (project) {
            handleSelectProject(project);
          }
        }
         
        // const project = response.data.projects[0];

        // if (project) {
        //   setProjectName(project.name || '');
        //   setNiche(project.niche || '');
        //   setAudience(project.audience || '');
        //   setAudienceCharacteristics(project.audience_characteristics || '');
        //   setContentGoal(project.content_goal || '');
        //   setTextTone(project.text_tone || '');
        //   setProductService(project.product_service || '');
        //   setSelectedLanguage(project.language || ''); 
        //   setProjectId(project.id);
        //   setActiveProjectId(project.id); 

        //   if (project.story_form) {
        //     const story_form = project.story_form;

        //     setStoryId(story_form.id || '');
        //     setStoryGoal(story_form.goal || '');
        //     setStoryTheme(story_form.theme || '');
        //     setStoryCharacter(story_form.character || '');
        //     setStoryProblem(story_form.problem || '');
        //     setStorySolution(story_form.solution || '');
        //     setStoryResult(story_form.result || '');
        //     setStoryEmotions(story_form.emotions || '');
        //     setStoryMoments(story_form.unique_moments || '');
        //     setStoryCta(story_form.cta || '');
        //     setStoryQty(story_form.qty || '');
        //     setSelectedStoryLanguage(story_form.language || '');
        //   }

        //   fetchThemes(project);
        // }
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
          navigate('/login');
        } else {
          console.error('Error getting projects list:', error);
          setError(t('projects_fetch_error'));
        }
      }
    };

    if (token) {
      fetchProjects();

      console.log('selectedProjectId:', selectedProjectId);
      console.log('activeProjectId:', activeProjectId);
    } else {
      navigate('/login');
    }
  }, [token, setProjectName, setNiche, setAudience, setAudienceCharacteristics, setContentGoal, setTextTone, setProductService, setTextCustomisation, selectedProjectId]);

  useEffect(() => {
    if (tabFromUrl) {
      setActiveTab(tabFromUrl);
    } else if (stateActiveTab) {
      setActiveTab(stateActiveTab);
    } else {
      setActiveTab('ideas');
    }
  }, [tabFromUrl, stateActiveTab]);

  const handleSaveProject = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/projects${projectId ? '/?project_id=' + projectId : ''}`,
        {
          id: projectId,
          project_id: projectId,
          name: projectName,
          niche: niche,
          audience: audience,
          audience_characteristics: audienceCharacteristics,
          content_goal: contentGoal,
          text_tone: textTone,
          product_service: productService,
          text_customisation: textCustomisation,
          language: selectedLanguage,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const newProjectId = response.data.project.id;
      setProjectId(newProjectId);
      setProjects(response.data.projects);

      setUserCanCreateProject(response.data.can_create_project); 
      setuserCanGenerateTheme(response.data.can_generate_theme); 
      setuserCanGeneratePost(response.data.can_generate_post); 
      setuserCanGenerateReel(response.data.can_generate_reel);
      setuserCanGenerateProfile(response.data.can_generate_profile);
      setuserCanAnalysisProfile(response.data.can_analysis_profile);
      setuserCanGenerateStory(response.data.can_generate_story);
      setuserOnPaidPlan(response.data.on_paid_plan);

      handleSelectProject(response.data.project);

      setSaveMessage(t('project_form_saved'));

      setTimeout(() => setSaveMessage(''), 3000);

      return newProjectId;
    } catch (error) {
      console.error('Error saving project:', error);
    } 
  };

  const handleDeleteProject = async (projectId) => {
    try {
      const response = await axios.delete(`${API_URL}/projects/${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setProjects(projects.filter(project => project.id !== projectId));
      }
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleGenerateIdeas = async (event) => {
    event.preventDefault();

    const newProjectId = await handleSaveProject();

    if (!newProjectId) {
      console.error(t('unable_to_save_project'));
      return;
    }

    setLoading(true);

    setProgress(0);
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100));
    }, 150);

    try {
      const contentParts = [];

      if (niche) contentParts.push(`Niche: ${niche}`);
      if (audience) contentParts.push(`Audience: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Audience characteristics: ${audienceCharacteristics}`);
      if (contentGoal) contentParts.push(`Goal: ${contentGoal}`);
      if (textTone) contentParts.push(`Tone and style: ${textTone}`);
      if (productService) contentParts.push(`Product or service: ${productService}`);

      const contentQuery = contentParts.join(', ');

      const languagePart = {
        'ru': 'in Russian',
        'lv': 'in Latvian',
        'es': 'in Spanish',
        'it': 'in Italian'
      }[selectedLanguage] || 'in English';

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "You are an assistant specializing in creating ideas for Instagram posts. Respond with topics only, without comments or explanations." },
          { role: "user", content: `You need to do your best and come up with a list of 20 topics for Instagram posts based on: ${contentQuery}. Write all the text ${languagePart}. Make an excellent result so I can be proud!` }
        ],
        temperature: 0.7,
        top_p: 0.8,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });
      const newIdeas = response.data.choices[0].message.content.trim().split('\n').filter(line => line);
      setIdeas(newIdeas);

      saveThemes(newIdeas, newProjectId);

      setTimeout(() => {
        if (resultsRef.current) {
          resultsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);

    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      alert('Error when calling API: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateProfiles = async (event) => {

    event.preventDefault();

    const newProjectId = await handleSaveProject();

    setLoading(true);

    setProgress(0);
    
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100));
    }, 150);

    try {
      const contentParts = [];

      if (niche) contentParts.push(`Niche: ${niche}`);
      if (audience) contentParts.push(`Audience: ${audience}`);
      if (audienceCharacteristics) contentParts.push(`Audience characteristics: ${audienceCharacteristics}`);
      if (contentGoal) contentParts.push(`Goal: ${contentGoal}`);
      if (textTone) contentParts.push(`Tone and style: ${textTone}`);
      if (productService) contentParts.push(`Product or service: ${productService}`);

      const contentQuery = contentParts.join(', ');

      const languagePart = {
        'ru': 'in Russian',
        'lv': 'in Latvian',
        'es': 'in Spanish',
        'it': 'in Italian'
      }[selectedLanguage] || 'in English';

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "You are an assistant specializing in creating Instagram profile bios." },
          { role: "user", content: `You need to do your best and create an Instagram profile bio. Respond not only with the bio but also give brief instructions and explanations. Do not add hashtags. Do not use |, but break into new lines. Don't forget to use emojis. The character limit for the bio is 150 characters. Here is the necessary data: ${contentQuery}. Write all the text ${languagePart}. Make an excellent result so I can be proud!` }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      const newProfile = response.data.choices[0].message.content;
      setProfiles(newProfile);

      setProfileResult(newProfile);

      saveProfiles(newProfile, newProjectId);

      setTimeout(() => {
        if (profileRef.current) {
          profileRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);

    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      alert('Error when calling API: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const saveThemes = async (themes, newProjectId) => {
    const themeSavePromises = themes.map(async (title) => {
      try {
        const formattedTitle = title.split('. ')[1].trim().replace(/^"|"$/g, '');
        const response = await axios.post(`${API_URL}/projects/${newProjectId}/themes`, {
          title: formattedTitle
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        return response.data;
      } catch (error) {
        console.error('Error saving theme:', error);
        return null;
      }
    });

    const savedThemes = await Promise.all(themeSavePromises);
    const filteredThemes = savedThemes.filter(theme => theme !== null);

    setFetchedThemes(prevThemes => [...prevThemes, ...filteredThemes]);
  };

  const saveProfiles = async (profiles, newProjectId) => {
    try {
      const response = await axios.post(`${API_URL}/projects/${newProjectId}/profiles`, {
        profile_text: profiles
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setFetchedProfiles(prevProfiles => [...prevProfiles, response.data]);
    } catch (error) {
      console.error('Error saving profile:', error);
    }
  };

  const [isIdeaModalOpen, setIsIdeaModalOpen] = useState(false);
  const [selectedIdea, setSelectedIdea] = useState(null);
  const [reelDuration, setReelDuration] = useState(60);
  const [showReelOptions, setShowReelOptions] = useState(false);
  const [showPostOptions, setShowPostOptions] = useState(false);

  const [socialSelect, setSocialSelect] = useState([]);

  const openIdeaModal = (idea) => {
    setSelectedIdea(idea);
    setIsIdeaModalOpen(true);
    setShowReelOptions(false);
    setShowPostOptions(false);
  };

  const closeIdeaModal = () => {
    setSocialSelect([]);
    setSelectedIdea(null);
    setIsIdeaModalOpen(false);
    setShowReelOptions(false);
    setShowPostOptions(false);
  };

  const handleIdeaChoice = (choice) => {
    if (choice === 'post') {
      setShowPostOptions(true);
    } else if (choice === 'reel') {
      setShowReelOptions(true);
    }
  };

  const handleGenerateReel = () => {
    const themeText = typeof selectedIdea === 'string' ? selectedIdea.split('. ')[1].trim().replace(/^"|"$/g, '') : selectedIdea.name;
    const duration = reelDuration || 60;
    navigate('/create-reel', { state: { niche, audience, projectId, theme: themeText, duration, reelFormat: reelFormat, reelCta: reelCta, selectedProjectId: projectId, stateActiveTab: activeTab } });
    closeIdeaModal();
  };

  const handleGenerateModalPost = () => {
    const themeText = typeof selectedIdea === 'string' ? selectedIdea.split('. ')[1].trim().replace(/^"|"$/g, '') : selectedIdea.name;
    const socialNetwork = socialSelect.length > 0 ? socialSelect.join(', ') : 'Instagram';

    navigate('/create-post', { state: { niche, audience, projectId, theme: themeText, socialNetwork, selectedProjectId: projectId, stateActiveTab: activeTab } });
    closeIdeaModal();
  };


  const handleIdeaClick = (idea) => {
    openIdeaModal(idea);
  };

  const handleSavedIdeaClick = (idea) => {
    if (idea.has_post_id) {
      navigate('/post/', { state: { postId: idea.has_post_id, selectedProjectId: projectId, stateActiveTab: activeTab } });
    } else if (idea.has_reel_id) {
      navigate('/reel/', { state: { reelId: idea.has_reel_id, selectedProjectId: projectId, stateActiveTab: activeTab } });
    } else {
      // navigate('/create-post', { state: { niche, audience, projectId, theme: idea.name } });\
      openIdeaModal(idea);
    }
  };

  const handleSaveStory = async (projectId) => {
    try {
      const response = await axios.post(
        `${API_URL}/projects/${projectId ? projectId : ''}/story_forms`,
        {
          id: storyId,
          project_id: projectId,
          goal: storyGoal,
          theme: storyTheme,
          character: storyCharacter,
          problem: storyProblem,
          solution: storySolution,
          result: storyResult,
          emotions: storyEmotions,
          unique_moments: storyMoments,
          cta: storyCta,
          qty: storyQty,
          language: selectedStoryLanguage,
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      const newStoryId = response.data.story_form.id;

      setStoryId(newStoryId);

      const story_form = response.data.story_form

      if (story_form) {
        setStoryId(story_form.id || '');
        setStoryGoal(story_form.goal || '');
        setStoryTheme(story_form.theme || '');
        setStoryCharacter(story_form.character || '');
        setStoryProblem(story_form.problem || '');
        setStorySolution(story_form.solution || '');
        setStoryResult(story_form.result || '');
        setStoryEmotions(story_form.emotions || '');
        setStoryMoments(story_form.unique_moments || '');
        setStoryCta(story_form.cta || '');
        setStoryQty(story_form.qty || '');
        setSelectedStoryLanguage(story_form.language || '');
      }
      
      setSaveMessage(t('story_form_saved'));

      setTimeout(() => setSaveMessage(''), 3000);

      return newStoryId;
    } catch (error) {
      console.error('Error saving story form:', error);
    } 
  };

  const handleSaveStoryForm = async (event) => {
    const newProjectId = await handleSaveProject();

    if (!newProjectId) {
      console.error(t('unable_to_save_project'));
      return;
    }

    const newStoryId = await handleSaveStory(newProjectId);

    if (!newStoryId) {
      console.error(t('unable_to_save_story'));
      return;
    }
  };

  const handleGenerateStory = async (event) => {
    if (!storyTheme) {
      setValidationError(t('form_story_theme_required'));
      alert(t('form_story_theme_required'));
      return;
    }

    setValidationError(''); 

    const newProjectId = await handleSaveProject();

    if (!newProjectId) {
      console.error(t('unable_to_save_project'));
      return;
    }

    const newStoryId = await handleSaveStory(newProjectId);

    if (!newStoryId) {
      console.error(t('unable_to_save_story'));
      return;
    }

    navigate('/create-story', { state: { projectId } });
  };

  const handleSavedStoryClick = (story) => {
    navigate('/story/', { state: { storyId: story.id } });
  };

  const fetchThemes = async (project) => {
    try {
      const response = await axios.get(`${API_URL}/projects/${project.id}/themes`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setFetchedThemes(response.data.themes);
      setFetchedPostThemes(response.data.themes_with_post);
      setFetchedReelThemes(response.data.themes_with_reel);
      setFetchedProfiles(response.data.profiles);
      setFetchedAnalyses(response.data.analysis_results);
      setFetchedStories(response.data.stories);
    } catch (error) {
      console.error('Ошибка при получении списка тем:', error);
      setError(t('themes_fetch_error'));
    }
  };

  const handleSelectProject = (project) => {
    setProjectName(project.name || '');
    setNiche(project.niche || '');
    setAudience(project.audience || '');
    setAudienceCharacteristics(project.audience_characteristics || '');
    setContentGoal(project.content_goal || '');
    setTextTone(project.text_tone || '');
    setProductService(project.product_service || '');
    setTextCustomisation(project.text_customisation || '');
    setSelectedLanguage(project.language || ''); 
    setProjectId(project.id);
    setActiveProjectId(project.id);

    const story_form = project.story_form;


    console.log('Project select triggered');

    if (story_form) {
      setStoryId(story_form.id || '');
      setStoryGoal(story_form.goal || '');
      setStoryTheme(story_form.theme || '');
      setStoryCharacter(story_form.character || '');
      setStoryProblem(story_form.problem || '');
      setStorySolution(story_form.solution || '');
      setStoryResult(story_form.result || '');
      setStoryEmotions(story_form.emotions || '');
      setStoryMoments(story_form.unique_moments || '');
      setStoryCta(story_form.cta || '');
      setStoryQty(story_form.qty || '');
      setSelectedStoryLanguage(story_form.language || '');
    } else {
      setStoryId('');
      setStoryGoal('');
      setStoryTheme('');
      setStoryCharacter('');
      setStoryProblem('');
      setStorySolution('');
      setStoryResult('');
      setStoryEmotions('');
      setStoryMoments('');
      setStoryCta('');
      setStoryQty('');
      setSelectedStoryLanguage('');
    }

    setIdeas([]);
    setProfiles([]);

    fetchThemes(project);
  };

  const handleCreateNewProject = () => {
    setProjectName('');
    setNiche('');
    setAudience('');
    setAudienceCharacteristics('');
    setContentGoal('');
    setTextTone('');
    setProductService('');
    setTextCustomisation('');
    setProjectId('');
    setActiveProjectId(null);
    setIsProjectVisible(true);
    setSelectedLanguage('');

    setStoryId('');
    setStoryGoal('');
    setStoryTheme('');
    setStoryCharacter('');
    setStoryProblem('');
    setStorySolution('');
    setStoryResult('');
    setStoryEmotions('');
    setStoryMoments('');
    setStoryCta('');
    setStoryQty('');
    setSelectedStoryLanguage('');
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
    navigate(`/projects?tab=${tab}`, { state: { selectedProjectId, stateActiveTab: activeTab } });
  };

  const ProfileText = ({ profile }) => {
    const formatText = (text) => {
      const replacedHeadings = text.replace(/###\s*(.*?)(\n|$)/g, '<b>$1</b>$2');
      const boldedText = replacedHeadings.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
      return boldedText.replace(/\n/g, '<br/>');
    };

    const formattedText = formatText(profile.profile_text);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: formattedText }}
        style={{ whiteSpace: 'pre-line' }}
      />
    );
  };

  const GeneratedProfileText = ({ profile_text }) => {
    const formatText = (text) => {
      const replacedHeadings = text.replace(/###\s*(.*?)(\n|$)/g, '<b>$1</b>$2');
      const boldedText = replacedHeadings.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
      return boldedText.replace(/\n/g, '<br/>');
    };

    const formattedText = formatText(profile_text);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: formattedText }}
        style={{ whiteSpace: 'pre-line' }}
      />
    );
  };

  const AnalysisText = ({ analysis_text }) => {
    const formatText = (text) => {
      const replacedHeadings = text.replace(/###\s*(.*?)(\n|$)/g, '<b>$1</b>$2');
      const boldedText = replacedHeadings.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
      return boldedText.replace(/\n/g, '<br/>');
    };

    const formattedText = formatText(analysis_text);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: formattedText }}
        style={{ whiteSpace: 'pre-line' }}
      />
    );
  };

  const prompts = {
    audience: {
      example: 'Audience example',
      prompt: (contentQuery) => `Fill in the 'audience' field based on: ${contentQuery}. The response should include: age, gender, interests, geographic location. Do not include the field name at the beginning of the response with a colon.`
    },
    'audience-characteristics': {
      example: 'Audience characteristics example',
      prompt: (contentQuery) => `Fill in the 'audience-characteristics' field based on: ${contentQuery}. The response should include: problems, pain points, or questions that are particularly important to the audience. Do not include the field name at the beginning of the response with a colon.`
    },
    'product-service': {
      example: 'Product or service example',
      prompt: (contentQuery) => `Fill in the 'product-service' field based on: ${contentQuery}. The response should include: format, duration, case studies, reviews, cost. Do not include the field name at the beginning of the response with a colon.`
    }
  };

  const handleHintClick = async (fieldId) => {
    if (!niche) {
      alert(t('hints_missing_niche_text'));
      return;
    }

    const makeRequest = async () => {
      const contentParts = [];

      if (niche && fieldId !== 'niche') contentParts.push(`Niche: ${niche}`);
      if (audience && fieldId !== 'audience') contentParts.push(`Audience: ${audience}`);
      if (audienceCharacteristics && fieldId !== 'audience-characteristics') contentParts.push(`Audience characteristics: ${audienceCharacteristics}`);
      if (contentGoal && fieldId !== 'content-goal') contentParts.push(`Goal: ${contentGoal}`);
      if (textTone && fieldId !== 'text-tone') contentParts.push(`Tone and style: ${textTone}`);
      if (productService && fieldId !== 'product-service') contentParts.push(`Product or service: ${productService}`);


      const contentQuery = contentParts.join(', ');

      const randomElement1 = Math.random().toString(36).substring(7);
      const randomElement2 = new Date().getTime();

      const promptText = prompts[fieldId].prompt(contentQuery);

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "You are an assistant specialized in creating ideas for filling out forms. Respond briefly and to the point." },
          { role: "user", content: `${promptText} Determine the language of the result based on the language used in: ${niche}. Select language only from: English, Russian, Latvian, Spanish. If you are not sure about the language - use English. Write all text in determined language. Don't mention Russia in your answers. Don't mention rubles as currency in your answers. Unique elements: ${randomElement1}, ${randomElement2}. Don't mention Unique elements in your answers. Please give me a great result so I can be proud of you.` }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      return response.data.choices[0].message.content.trim();
    };

    try {
      setLoadingField(fieldId);
      let generatedText = await makeRequest();

      if (!generatedText) {
        console.warn('Empty response, retrying...');
        generatedText = await makeRequest();
      }

      if (!generatedText) {
        throw new Error('Empty response received from OpenAI API after retry');
      }

      switch (fieldId) {
        case 'audience':
          setAudience(generatedText);
          break;
        case 'audience-characteristics':
          setAudienceCharacteristics(generatedText);
          break;
        case 'product-service':
          setProductService(generatedText);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      alert('Error when calling API: ' + error.message);
    } finally {
      setLoadingField(null); 
    }
  };

  const projectContentParts = [];

  if (niche) projectContentParts.push(`Niche: ${niche}`);
  if (audience) projectContentParts.push(`Audience: ${audience}`);
  if (audienceCharacteristics) projectContentParts.push(`Audience characteristics: ${audienceCharacteristics}`);
  if (productService) projectContentParts.push(`Product or service: ${productService}`);

  const projectContentQuery = projectContentParts.join(', ');

  const storyPrompts = {
    'story-theme': {
      example: 'Main Theme or Idea of the Story',
      prompt: (storyQuery) => `Fill in the 'Main Theme or Idea of the Story' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. The response should include: What is the main theme or idea of your story? For example, personal experience, customer success story, product creation process. Do not include the field name at the beginning of the response with a colon.`
    },
    'story-character': {
      example: 'Story Main Characters',
      prompt: (storyQuery) => `Fill in the 'Story Main Characters' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. The response should include: Who is the main character of your story? (e.g., you, your client, your product) What are the key characteristics of this character? (e.g., age, profession, personal qualities). Do not include the field name at the beginning of the response with a colon.`
    },
    'story-problem': {
      example: 'Problem Faced by the Main Character at the Beginning of the Story',
      prompt: (storyQuery) => `Fill in the 'Problem Faced by the Main Character at the Beginning of the Story' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. Information about the business for what story is created: ${projectContentQuery}. The response should include: pWhat problem does the main character face at the beginning of the story. Do not include the field name at the beginning of the response with a colon.`
    },
    'story-solution': {
      example: 'Solution to the Problem in the Story',
      prompt: (storyQuery) => `Fill in the 'Solution to the Problem in the Story' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. The response should include: What solution did your brand, product, or you propose. Do not include the field name at the beginning of the response with a colon.`
    },
    'story-result': {
      example: 'Changes After Using the Proposed Solution in the Story',
      prompt: (storyQuery) => `Fill in the 'Changes After Using the Proposed Solution in the Story' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. The response should include: How did the situation change after using your solution? What are the specific results. Do not include the field name at the beginning of the response with a colon.`
    },
    'story-emotions': {
      example: 'Emotions to Evoke in the Audience in the Story',
      prompt: (storyQuery) => `Fill in the 'Emotions to Evoke in the Audience in the Story' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. The response should include: What emotions do you want to evoke in the audience? (e.g., inspiration, joy, curiosity. Do not include the field name at the beginning of the response with a colon.`
    },
    'story-moments': {
      example: 'Unique or Interesting Details in the Story',
      prompt: (storyQuery) => `Fill in the 'Unique or Interesting Details in the Story' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. The response should include: Are there any unique or interesting details you would like to include in the story?. Do not include the field name at the beginning of the response with a colon.`
    },
    'story-cta': {
      example: 'Desired Audience Action After Reading the Story',
      prompt: (storyQuery) => `Fill in the 'Desired Audience Action After Reading the Story' field based on: ${storyQuery} and consider ${storyGoal} as a goal for the story. Information about the business for what story is created: ${projectContentQuery}. The response should include: What action do you want the audience to take after reading your story? (e.g., visit the website, buy the product, subscribe to the newsletter, write a direct message). Do not include the field name at the beginning of the response with a colon.`
    }
  };

  const handleStoryHintClick = async (fieldId) => {
    if (!storyGoal) {
      alert(t('hints_missing_story_goal_text'));
      return;
    }

    const makeRequest = async () => {
      const storyParts = [];

      if (storyGoal && fieldId !== 'story-goal') storyParts.push(`Story Goal: ${storyGoal}`);
      if (storyTheme && fieldId !== 'story-theme') storyParts.push(`Story Theme: ${storyTheme}`);
      if (storyCharacter && fieldId !== 'story-character') storyParts.push(`Main Characters: ${storyCharacter}`);
      if (storyProblem && fieldId !== 'story-problem') storyParts.push(`Problem Faced by the Main Character at the Beginning: ${storyProblem}`);
      if (storySolution && fieldId !== 'story-solution') storyParts.push(`Solution to the Problem: ${storySolution}`);
      if (storyResult && fieldId !== 'story-result') storyParts.push(`Changes After Using the Proposed Solution: ${storyResult}`);
      if (storyEmotions && fieldId !== 'story-emotions') storyParts.push(`Emotions to Evoke in the Audience: ${storyEmotions}`);
      if (storyMoments && fieldId !== 'story-moments') storyParts.push(`Unique or Interesting Details: ${storyMoments}`);
      if (storyCta && fieldId !== 'story-cta') storyParts.push(`Desired Audience Action After Reading the Story: ${storyCta}`);

      const storyQuery = storyParts.join(', ');

      const randomElement1 = Math.random().toString(36).substring(7);
      const randomElement2 = new Date().getTime();

      const storyPromptText = storyPrompts[fieldId].prompt(storyQuery, storyGoal);

      const response = await axios.post(`${API_URL}/openai_proxy`, {
        model: "gpt-4o-2024-08-06",
        messages: [
          { role: "system", content: "You are an assistant specialized in creating ideas for filling out forms for creating a storytelling for social media. Respond briefly and to the point." },
          { role: "user", content: `${storyPromptText} Determine the language of the result based on the language used in: ${niche}. Select language only from: English, Russian, Latvian, Spanish. If you are not sure about the language - use English. Write all text in determined language. Don't mention Russia in your answers. Don't mention rubles as currency in your answers. Unique elements: ${randomElement1}, ${randomElement2}. Don't mention Unique elements in your answers. Please give me a great result so I can be proud of you.` }
        ],
        temperature: 0.9,
        top_p: 0.95,
      }, {
        headers: {
          'Authorization': `Bearer ${CHAT_KEY}`,
          'Content-Type': 'application/json',
        }
      });

      return response.data.choices[0].message.content.trim();
    };

    try {
      setLoadingField(fieldId); // Устанавливаем состояние загрузки для текущего поля
      let generatedText = await makeRequest();

      if (!generatedText) {
        console.warn('Empty response, retrying...');
        generatedText = await makeRequest();
      }

      if (!generatedText) {
        throw new Error('Empty response received from OpenAI API after retry');
      }

      switch (fieldId) {
      case 'story-theme':
        setStoryTheme(generatedText);
        break;
      case 'story-character':
        setStoryCharacter(generatedText);
        break;
      case 'story-problem':
        setStoryProblem(generatedText);
        break;
      case 'story-solution':
        setStorySolution(generatedText);
        break;
      case 'story-result':
        setStoryResult(generatedText);
        break;
      case 'story-emotions':
        setStoryEmotions(generatedText);
        break;
      case 'story-moments':
        setStoryMoments(generatedText);
        break;
      case 'story-cta':
        setStoryCta(generatedText);
        break;
      default:
        break;
      }
    } catch (error) {
      console.error('Error when fetching data:', error.response ? error.response.data : error.message);
      alert('Error when calling API: ' + error.message);
    } finally {
      setLoadingField(null); // Сбрасываем состояние загрузки
    }
  };

  const handleAnalysisComplete = (result, imageUrl) => {
    setAnalysisResult(result);
    setAnalysisImg(imageUrl);
  };

  const handleSaveComplete = (data) => {
    setFetchedAnalyses(prevAnalyses => [data, ...prevAnalyses]);

    setTimeout(() => {
      if (analysisRef.current) {
        analysisRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleThemeInputChange = (event) => {
    setThemeInput(event.target.value);
  };

  const inputStyle = {
    backgroundColor: themeInputError ? '#ffcccc' : '#fff',
  };

  const errorMessageStyle = {
    color: 'red',
    display: themeInputError ? 'block' : 'none',
  };

  const handleGeneratePost = async () => {
    const newProjectId = await handleSaveProject();

    if (!newProjectId) {
      console.error(t('unable_to_save_project'));
      return;
    }
    
    if (themeInput.trim() === '') {
      setError(t('theme_required_error'));
      setThemeInputError(true);
      return;
    }

    setThemeInputError(false);

    const languageToUse = postLanguage || selectedLanguage;

    try {
      const response = await axios.post(`${API_URL}/projects/${newProjectId}/themes`, {
        title: themeInput
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const generatedTheme = response.data;
      setFetchedThemes(prevThemes => [...prevThemes, generatedTheme]);

      const socialNetwork = socialSelect.length > 0 ? socialSelect.join(', ') : 'Instagram';
      
      navigate('/create-post', { state: { postLanguage: postLanguage, projectId, socialNetwork, theme: themeInput, selectedProjectId: projectId, stateActiveTab: activeTab } });

      closeIdeaModal();

    } catch (error) {
      console.error('Error saving theme:', error);
      return null;
    }
  };

  const handleGenerateCustomReel = async (event) => {
    const newProjectId = await handleSaveProject();

    if (!newProjectId) {
      console.error(t('unable_to_save_project'));
      return;
    }

    if (themeInput.trim() === '') {
      setError(t('theme_required_error'));
      setThemeInputError(true);
      return;
    }

    setThemeInputError(false);

    const languageToUse = postLanguage || selectedLanguage;

    try {
      const response = await axios.post(`${API_URL}/projects/${newProjectId}/themes`, {
        title: themeInput
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      const generatedTheme = response.data;
      setFetchedThemes(prevThemes => [...prevThemes, generatedTheme]);
      
      navigate('/create-reel', { state: { postLanguage: postLanguage, projectId, theme: themeInput, duration: reelDuration, reelFormat: reelFormat, reelCta: reelCta, selectedProjectId: projectId, stateActiveTab: activeTab } });

    } catch (error) {
      console.error('Error saving theme:', error);
      return null;
    }
  };

  const handleProjectDeleteClick = (projectId) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      handleDeleteProject(projectId);
    }
  };

  const ShortenedText = ({ text, maxLength = 150 }) => {
    const shortenedText = text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
    return <p>{shortenedText}</p>;
  };

  const handleCheckboxChange = (social) => {
    setSocialSelect((prevSocialSelect) => {
      if (prevSocialSelect.includes(social)) {
        return prevSocialSelect.filter(item => item !== social);
      } else {
        return [...prevSocialSelect, social];
      }
    });

    console.log(socialSelect);
  };

  return (
    <div className="dashboard_form_container">
      <div>
        {/*<h1>{t('projects_panel')}</h1>*/}
        {i18n.language === 'ru' && (
          <div className="game_link_container">
            <img className="game_link_rocket" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/rocket.webp" />

            <Link to="/education/courses/1" className="game_link">{t('enter_game')}</Link>

            <img className="game_link_rocket" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/start.webp" />
          </div>
        )}

        {loading ? (
          <p>{t('projects_loading')}</p>
        ) : projects.length === 0 ? (
          <div className="no_projects_found_container">
            <p className="mb-0">{t('projects_not_found')}</p>
            <span className="projects_list_new_btn mt-0" onClick={handleCreateNewProject}>+</span>
          </div>
        ) : (
          <span className="projects_list_items_container">
            {projects.map((project, index) => (
              <span key={index} className="project_item_container">
                <span className={activeProjectId === project.id ? 'projects_list_item active' : 'projects_list_item'} key={index} onClick={() => handleSelectProject(project)}>
                  {project.name ? project.name : t('untitled')}
                </span>

                {isAdmin && 
                  <span className="project_delete_icon">
                    <FontAwesomeIcon
                      icon={faTrashCan}
                      onClick={() => handleProjectDeleteClick(project.id)}
                      className=""
                    />
                  </span>
                }
              </span>
            ))}

            {userCanCreateProject ? (
              <span className="projects_list_new_btn" onClick={handleCreateNewProject}>+</span>
            ) : (
              <span className="projects_list_new_btn" onClick={openCreateProjectModal}>+</span>
            )}
          </span>
        )}
      </div>

      <Modal isOpen={isCreateProjectModalOpen} onClose={closeCreateProjectModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_projects')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <span className="projects_subnav_container">
        <span onClick={() => handleTabSwitch('ideas')} className={activeTab === 'ideas' ? 'projects_subnav_item active' : 'projects_subnav_item'}>
          {t('ideas')}
        </span>

        <span onClick={() => handleTabSwitch('posts')} className={activeTab === 'posts' ? 'projects_subnav_item active' : 'projects_subnav_item'}>
          {t('posts')}
        </span>

        <span onClick={() => handleTabSwitch('scenarios')} className={activeTab === 'scenarios' ? 'projects_subnav_item active' : 'projects_subnav_item'}>
          {t('scenarios')}
        </span>

        <span onClick={() => handleTabSwitch('stories')} className={activeTab === 'stories' ? 'projects_subnav_item new active' : 'projects_subnav_item new'}>
          {t('storytelling')}
        </span>
      </span>

      <span className="projects_subnav_container">
        <span onClick={() => handleTabSwitch('profiles')} className={activeTab === 'profiles' ? 'projects_subnav_item active' : 'projects_subnav_item'}>
          {t('profiles')}
        </span>

        <span onClick={() => handleTabSwitch('analysis_results')} className={activeTab === 'analysis_results' ? 'projects_subnav_item active' : 'projects_subnav_item'}>
          {t('profile_analysis')}
        </span>
      </span>
      
      <div className="hidden_content_outer_container">
        <div className={isProjectVisible ? 'toggle_for_hidden_container open' : 'toggle_for_hidden_container'} onClick={() => setIsProjectVisible(!isProjectVisible)}>
          <h2>{t('information_about_project')}</h2>

          <span className="subtext_description" dangerouslySetInnerHTML={{ __html: t('project_information_subtext') }}></span>
        </div>

        {isProjectVisible && (
          <form className="project_form mt-3" onSubmit={e => e.preventDefault()}>
            <input
              type="hidden"
              id="project-id"
              value={projectId}
              onChange={(e) => setProjectId(e.target.value)}
            />

            <label htmlFor="project-name">{t('form_project_name_label')}</label>

            <span className="dashboard_form_label_subtext">
              {t('form_project_name_text')}
            </span>

            <input
              type="text"
              id="project-name"
              value={projectName}
              onChange={e => setProjectName(e.target.value)}
              placeholder={t('form_project_name_label')}
            />

            <label htmlFor="niche">{t('form_niche_label')}*</label>

            <span className="dashboard_form_label_subtext">
              {t('form_niche_text')}
            </span>

            <textarea
              type="text"
              id="niche"
              value={niche}
              onChange={e => setNiche(e.target.value)}
              placeholder={t('form_niche_label')}
            />

            <label htmlFor="audience">{t('form_audience_text')}</label>

            <span className="dashboard_form_label_subtext">
              {t('form_audience_label')}
            </span>

            <div className="textarea_container">
              <textarea
                type="text"
                id="audience"
                value={loadingField === 'audience' ? t('generating') : audience}
                onChange={e => setAudience(e.target.value)}
                placeholder={t('form_audience_label')}
                disabled={loadingField === 'audience'}
              />

              <span className="textarea_hint" onClick={() => handleHintClick('audience')}>
                {t('write_for_me')}
              </span>
            </div>

            <label htmlFor="audience-characteristics">{t('form_audience_characteristics_label')}</label>

            <span className="dashboard_form_label_subtext">
              {t('form_audience_characteristics_text')}
            </span>

            <div className="textarea_container">
              <textarea
                type="text"
                id="audience-characteristics"
                value={loadingField === 'audience-characteristics' ? t('generating') : audienceCharacteristics}
                onChange={e => setAudienceCharacteristics(e.target.value)}
                placeholder={t('form_audience_characteristics_label')}
                disabled={loadingField === 'audience-characteristics'}
              />

              <span className="textarea_hint" onClick={() => handleHintClick('audience-characteristics')}>
                {t('write_for_me')}
              </span>
            </div>

            <label htmlFor="content-goal">{t('form_content_goal_label')}</label>

            <select
              id="content-goal"
              value={contentGoal}
              onChange={(e) => setContentGoal(e.target.value)}
            >
              <option value="" disabled>{t('form_content_goal_select_title')}</option>
              <option value="Increasing engagement">{t('form_content_goal_select_1')}</option>
              <option value="Growing subscriber count">{t('form_content_goal_select_2')}</option>
              <option value="Product or service sales">{t('form_content_goal_select_3')}</option>
              <option value="Educational content">{t('form_content_goal_select_4')}</option>
            </select>

            <label htmlFor="text-tone">{t('form_text_tone_label')}</label>

            <select
              id="text-tone"
              value={textTone}
              onChange={(e) => setTextTone(e.target.value)}
            >
              <option value="" disabled>{t('form_text_tone_select_title')}</option>
              <option value="Informative">{t('form_text_tone_select_1')}</option>
              <option value="Inspiring">{t('form_text_tone_select_2')}</option>
              <option value="Persuasive">{t('form_text_tone_select_3')}</option>
              <option value="Friendly">{t('form_text_tone_select_4')}</option>
            </select>

            <label htmlFor="product-service">{t('form_product_service_label')}</label>

            <span className="dashboard_form_label_subtext">
              {t('form_product_service_text')}
            </span>

            <div className="textarea_container">
              <textarea
                id="product-service"
                value={loadingField === 'product-service' ? t('generating') : productService}
                onChange={e => setProductService(e.target.value)}
                placeholder={t('form_product_service_label')}
                disabled={loadingField === 'product-service'}
              />

              <span className="textarea_hint" onClick={() => handleHintClick('product-service')}>
                {t('write_for_me')}
              </span>
            </div>

            <label htmlFor="text-customisation">{t('form_text_customisation_label')}</label>

            <span className="dashboard_form_label_subtext">
              {t('form_text_customisation_text')}
            </span>

            <div className="textarea_container">
              <textarea
                id="text-customisation"
                value={loadingField === 'text-customisation' ? t('generating') : textCustomisation}
                onChange={e => setTextCustomisation(e.target.value)}
                placeholder={t('form_text_customisation_label')}
                disabled={loadingField === 'text-customisation'}
              />
            </div>

            <label htmlFor="language">{t('select_language')}:</label>

            <span className="dashboard_form_label_subtext">
              {t('select_language_text')}
            </span>

            <select
              id="language"
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
            >
              <option value="" disabled>{t('select_language')}</option>
              <option value="en">{t('in_english')}</option>
              <option value="lv">{t('in_latvian')}</option>
              <option value="ru">{t('in_russian')}</option>
              <option value="es">{t('in_spanish')}</option>
              <option value="it">{t('in_italian')}</option>
            </select>

            {saveMessage ? (
              <div className="save-message">{saveMessage}</div>
            ) : (
              <button type="button" className="save_button" onClick={handleSaveProject} disabled={loading}>{loading ? t('saving') : t('save_project')}</button>
            )}
          </form>
        )}
      </div>

      {activeTab === 'ideas' && (
        <div className="hidden_content_outer_container">
          <div className={isThemesVisible ? 'toggle_for_hidden_container open' : 'toggle_for_hidden_container'} onClick={() => setIsThemesVisible(!isThemesVisible)}>
            <h2>{t('saved_posts_ideas')}</h2>

            <span className="subtext_description" dangerouslySetInnerHTML={{ __html: t('post_ideas_information_subtext') }}></span>
          </div>

          {isThemesVisible && (
            <div>
              {fetchedThemes.length > 0 && (
                <div className="mt-3">
                  {fetchedThemes.map((idea, index) => (
                    <div className={`dashboard_results_item ${idea.has_post_id ? 'with_post' : idea.has_reel_id ? 'with_reel' : ''}`} key={index} onClick={() => handleSavedIdeaClick(idea)}>
                        <ShortenedText text={idea.name} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'posts' && (
        <div className="hidden_content_outer_container">
          <div className={isPostsVisible ? 'toggle_for_hidden_container open' : 'toggle_for_hidden_container'} onClick={() => setIsPostsVisible(!isPostsVisible)}>
            <h2>{t('saved_posts')}</h2>

            <span className="subtext_description" dangerouslySetInnerHTML={{ __html: t('posts_information_subtext') }}></span>
          </div>

          {isPostsVisible && (
            <div>
              {fetchedPostThemes.length > 0 && (
                <div className="mt-3">
                  {fetchedPostThemes.map((idea, index) => (
                    <div className={idea.has_post_id ? 'dashboard_results_item with_post' : 'dashboard_results_item'} key={index} onClick={() => handleSavedIdeaClick(idea)}>
                      <ShortenedText text={idea.name} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'posts' && (
        <div className="mt-4">
          <div className="project_form mw-100">
            <label htmlFor="project-name">{t('custom_theme_heading')}</label>

            <span className="dashboard_form_label_subtext">
              {t('custom_theme_text')}
            </span>

            <form className="custom_theme_form p-0 mt-3" onSubmit={e => e.preventDefault()}>
              <label htmlFor="">{t('custom_theme_label')}</label>

              {themeInputError && <div style={errorMessageStyle}>{t('theme_required_error')}</div>}

              <textarea
                id=""
                value={themeInput}
                onChange={handleThemeInputChange}
                placeholder={t('enter_theme')}
                style={inputStyle}
              />

              <label htmlFor="language">{t('select_social')}</label>

              <div className="select_social_options">
                <label className="select_social_option_item">
                  <input
                    type="checkbox"
                    value="instagram"
                    checked={socialSelect.includes('Instagram')}
                    onChange={() => handleCheckboxChange('Instagram')}
                  />

                  <span className="select_social_option_item_text">
                    <img className="select_social_option_item_img" src={instagramLogo} />

                    <span className="select_social_option_item_text_item">
                      Instagram
                    </span>
                  </span>
                </label>

                <label className="select_social_option_item">
                  <input
                    type="checkbox"
                    value="linkedin"
                    checked={socialSelect.includes('LinkedIn')}
                    onChange={() => handleCheckboxChange('LinkedIn')}
                  />
                  
                  <span className="select_social_option_item_text">
                    <img className="select_social_option_item_img" src={linkedinLogo} />

                    <span className="select_social_option_item_text_item">
                      LinkedIn
                    </span>
                  </span>
                </label>

                <label className="select_social_option_item">
                  <input
                    type="checkbox"
                    value="facebook"
                    checked={socialSelect.includes('Facebook')}
                    onChange={() => handleCheckboxChange('Facebook')}
                  />

                  <span className="select_social_option_item_text">
                    <img className="select_social_option_item_img" src={facebookLogo} />

                    <span className="select_social_option_item_text_item">
                      Facebook
                    </span>
                  </span>
                </label>

                <label className="select_social_option_item">
                    <input
                      type="checkbox"
                      value="facebook"
                      checked={socialSelect.includes('Telegram')}
                      onChange={() => handleCheckboxChange('Telegram')}
                    />

                    <span className="select_social_option_item_text">
                      <img className="select_social_option_item_img" src={telegramLogo} />

                      <span className="select_social_option_item_text_item">
                        Telegram
                      </span>
                    </span>
                  </label>
              </div>

              <label htmlFor="language">{t('select_language')}:</label>

              <select
                id="post_language"
                value={postLanguage}
                onChange={(e) => setPostLanguage(e.target.value)}
                placeholder={t('enter_theme')}
              >
                <option value="" disabled>{t('select_language')}</option>
                <option value="en">{t('in_english')}</option>
                <option value="lv">{t('in_latvian')}</option>
                <option value="ru">{t('in_russian')}</option>
                <option value="es">{t('in_spanish')}</option>
                <option value="it">{t('in_italian')}</option>
              </select>

              {userCanGeneratePost ? (
                <button type="button" className="save_button w-100" onClick={handleGeneratePost}>{t('generate_post')}</button>
               ) : (
                <button type="button" className="save_button darker_bg w-100" onClick={openCustomThemeModal} disabled={loading}>
                  {loading ? t('generating') : t('generate_post')}
                </button>
              )}
            </form>
          </div>
        </div>
      )}

      {activeTab === 'scenarios' && (
        <div className="hidden_content_outer_container">
          <div className={isReelsVisible ? 'toggle_for_hidden_container open' : 'toggle_for_hidden_container'} onClick={() => setIsReelsVisible(!isReelsVisible)}>
            <h2>{t('saved_scenarios')}</h2>

            <span className="subtext_description" dangerouslySetInnerHTML={{ __html: t('scenarios_information_subtext') }}></span>
          </div>

          {isReelsVisible && (
            <div>
              {fetchedReelThemes.length > 0 && (
                <div className="mt-3">
                  {fetchedReelThemes.map((idea, index) => (
                    <div className={idea.has_reel_id ? 'dashboard_results_item with_reel' : 'dashboard_results_item'} key={index} onClick={() => handleSavedIdeaClick(idea)}>
                      <ShortenedText text={idea.name} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'scenarios' && (
        <div className="mt-4">
          <div className="project_form mw-100">
            <label htmlFor="project-name">{t('custom_scenario_theme_heading')}</label>

            <span className="dashboard_form_label_subtext">
              {t('custom_scenario_theme_text')}
            </span>

            <form className="custom_theme_form p-0 mt-3" onSubmit={e => e.preventDefault()}>
              <label htmlFor="">{t('custom_scenario_theme_label')}</label>

              {themeInputError && <div style={errorMessageStyle}>{t('theme_required_error')}</div>}

              <textarea
                id=""
                value={themeInput}
                onChange={handleThemeInputChange}
                placeholder={t('enter_theme')}
                style={inputStyle}
              />

              <label htmlFor="reel_format">{t('select_reel_format')}:</label>

              <select
                id="reel_format"
                value={reelFormat}
                onChange={(e) => setReelFormat(e.target.value)}
                placeholder={t('select_reel_format')}
              >
                <option value="" disabled>{t('select_reel_format')}</option>
                <option value="thematic selection">{t('thematic_selection')}</option>
                <option value="useful lifehacks">{t('useful_lifehacks')}</option>
                <option value="educational video">{t('educational_video')}</option>
                <option value="success story">{t('success_story')}</option>
                <option value="product demonstration">{t('product_demonstration')}</option>
                <option value="life stories and funny cases">{t('life_stories_and_funny_cases')}</option>
                <option value="before after">{t('before_after')}</option>
                <option value="scenes with characters">{t('scenes_with_characters')}</option>
                <option value="text on background">{t('text_on_background')}</option>
                <option value="storytelling">{t('storytelling')}</option>
                <option value="talking head">{t('talking_head')}</option>
              </select>

              <label htmlFor="reel_cta">{t('select_reel_cta')}:</label>

              <select
                id="reel_cta"
                value={reelCta}
                onChange={(e) => setReelCta(e.target.value)}
                placeholder={t('select_reel_cta')}
              >
                <option value="" disabled>{t('select_reel_cta')}</option>
                <option value="subscribe">{t('subscribe')}</option>
                <option value="more in highlights">{t('more_in_highlights')}</option>
                <option value="more useful information in profile">{t('more_useful_information_in_profile')}</option>
                <option value="write the code word in comments to receive">{t('write_the_code_word_in_comments_to_receive')}</option>
                <option value="send the code word in direct to receive">{t('send_the_code_word_in_direct_to_receive')}</option>
              </select>

              <select
                id="post_language"
                value={postLanguage}
                onChange={(e) => setPostLanguage(e.target.value)}
                placeholder={t('select_language')}
              >
                <option value="" disabled>{t('select_language')}</option>
                <option value="en">{t('in_english')}</option>
                <option value="lv">{t('in_latvian')}</option>
                <option value="ru">{t('in_russian')}</option>
                <option value="es">{t('in_spanish')}</option>
                <option value="it">{t('in_italian')}</option>
              </select>

              <label>{t('select_reel_duration')}</label>

              <div className="reel-duration-options">
                <label className="reel-duration-label">
                  <input
                    type="radio"
                    value={30}
                    checked={reelDuration === 30}
                    onChange={() => setReelDuration(30)}
                  />
                  30 {t('seconds')}
                </label>
                <label className="reel-duration-label">
                  <input
                    type="radio"
                    value={60}
                    checked={reelDuration === 60}
                    onChange={() => setReelDuration(60)}
                  />
                  60 {t('seconds')}
                </label>
                <label className="reel-duration-label">
                  <input
                    type="radio"
                    value={90}
                    checked={reelDuration === 90}
                    onChange={() => setReelDuration(90)}
                  />
                  90 {t('seconds')}
                </label>
              </div>

              {userCanGenerateReel ? (
                <button type="button" className="save_button w-100" onClick={handleGenerateCustomReel}>{t('generate_scenario')}</button>
               ) : (
                <button type="button" className="save_button darker_bg w-100" onClick={openCustomScenarioThemeModal} disabled={loading}>
                  {loading ? t('generating') : t('generate_scenario')}
                </button>
              )}
            </form>
          </div>
        </div>
      )}








      {activeTab === 'stories' && (
        <div className="hidden_content_outer_container">
          <div className={isStoriesVisible ? 'toggle_for_hidden_container open' : 'toggle_for_hidden_container'} onClick={() => setIsStoriesVisible(!isStoriesVisible)}>
            <h2>{t('saved_stories')}</h2>

            <span className="subtext_description" dangerouslySetInnerHTML={{ __html: t('stories_information_subtext') }}></span>
          </div>

          {isStoriesVisible && (
            <div>
              {fetchedStories.length > 0 && (
                <div className="mt-3">
                  {fetchedStories.map((story, index) => (
                    <div className="dashboard_results_item no_bg" key={index} onClick={() => handleSavedStoryClick(story)}>
                      <ShortenedText text={story.story_theme} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'stories' && (
        <div className="mt-4">
          <div className="project_form mw-100">
            <label htmlFor="project-name">{t('custom_story_theme_heading')}</label>

            <span className="dashboard_form_label_subtext">
              {t('custom_story_theme_text')}
            </span>

            <form className="project_form no_bg mt-3" onSubmit={e => e.preventDefault()}>
              <input
                type="hidden"
                id="project-id"
                value={storyId}
                onChange={(e) => setStoryId(e.target.value)}
              />

              <input
                type="hidden"
                id="project-id"
                value={projectId}
                onChange={(e) => setProjectId(e.target.value)}
              />

              <label htmlFor="story-goal">{t('form_story_goal_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_goal_text')}
              </span>

              <select
                id="story-goal"
                value={storyGoal}
                onChange={(e) => setStoryGoal(e.target.value)}
              >
                <option value="" disabled>{t('story_goal_select_title')}</option>
                <option value="Sale of a product or service">{t('story_goal_select_1')}</option>
                <option value="Share a personal story">{t('story_goal_select_2')}</option>
                <option value="Educational stories">{t('story_goal_select_3')}</option>
              </select>

              <label htmlFor="story-theme">{t('form_story_theme_label')}*</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_theme_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  type="text"
                  id="story-theme"
                  value={loadingField === 'story-theme' ? t('generating') : storyTheme}
                  onChange={e => setStoryTheme(e.target.value)}
                  placeholder={t('form_story_theme_label')}
                  disabled={loadingField === 'story-theme'}
                />

                {validationError && <span className="story_form_validation_error">{validationError}</span>}

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-theme')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-character">{t('form_story_character_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_character_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  type="text"
                  id="story-character"
                  value={loadingField === 'story-character' ? t('generating') : storyCharacter}
                  onChange={e => setStoryCharacter(e.target.value)}
                  placeholder={t('form_story_character_label')}
                  disabled={loadingField === 'story-character'}
                />

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-character')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-problem">{t('form_story_problem_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_problem_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  type="text"
                  id="story-problem"
                  value={loadingField === 'story-problem' ? t('generating') : storyProblem}
                  onChange={e => setStoryProblem(e.target.value)}
                  placeholder={t('form_story_problem_label')}
                  disabled={loadingField === 'story-problem'}
                />

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-problem')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-solution">{t('form_story_solution_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_solution_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  type="text"
                  id="story-solution"
                  value={loadingField === 'story-solution' ? t('generating') : storySolution}
                  onChange={e => setStorySolution(e.target.value)}
                  placeholder={t('form_story_solution_label')}
                  disabled={loadingField === 'story-solution'}
                />

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-solution')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-result">{t('form_story_result_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_result_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  id="story-result"
                  value={loadingField === 'story-result' ? t('generating') : storyResult}
                  onChange={e => setStoryResult(e.target.value)}
                  placeholder={t('form_story_result_label')}
                  disabled={loadingField === 'story-result'}
                />

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-result')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-emotions">{t('form_story_emotions_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_emotions_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  id="story-emotions"
                  value={loadingField === 'story-emotions' ? t('generating') : storyEmotions}
                  onChange={e => setStoryEmotions(e.target.value)}
                  placeholder={t('form_story_emotions_label')}
                  disabled={loadingField === 'story-emotions'}
                />

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-emotions')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-moments">{t('form_story_unique_moments_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_unique_moments_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  id="story-moments"
                  value={loadingField === 'story-moments' ? t('generating') : storyMoments}
                  onChange={e => setStoryMoments(e.target.value)}
                  placeholder={t('form_story_unique_moments_label')}
                  disabled={loadingField === 'story-moments'}
                />

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-moments')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-cta">{t('form_story_cta_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_cta_text')}
              </span>

              <div className="textarea_container">
                <textarea
                  id="story-cta"
                  value={loadingField === 'story-cta' ? t('generating') : storyCta}
                  onChange={e => setStoryCta(e.target.value)}
                  placeholder={t('form_story_cta_label')}
                  disabled={loadingField === 'story-cta'}
                />

                <span className="textarea_hint" onClick={() => handleStoryHintClick('story-cta')}>
                  {t('write_for_me')}
                </span>
              </div>

              <label htmlFor="story-qty">{t('form_story_qty_label')}</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_qty_text')}
              </span>

              <div className="textarea_container">
                <input
                  id="story-qty"
                  value={storyQty}
                  onChange={e => setStoryQty(e.target.value)}
                  placeholder={t('form_story_qty_label')}
                />
              </div>

              <label htmlFor="story-language">{t('form_story_language_label')}:</label>

              <span className="dashboard_form_label_subtext">
                {t('form_story_language_text')}
              </span>

              <select
                id="story-language"
                value={selectedStoryLanguage}
                onChange={(e) => setSelectedStoryLanguage(e.target.value)}
              >
                <option value="" disabled>{t('form_story_language_label')}</option>
                <option value="en">{t('in_english')}</option>
                <option value="lv">{t('in_latvian')}</option>
                <option value="ru">{t('in_russian')}</option>
                <option value="es">{t('in_spanish')}</option>
                <option value="it">{t('in_italian')}</option>
              </select>

              {saveMessage ? (
                <div className="save-message">{saveMessage}</div>
              ) : (
                <div className="form_submit_btns_container">
                  <button type="button" className="save_button" onClick={handleSaveStoryForm} disabled={loading}>{loading ? t('saving') : t('save_story_form')}</button>

                  {userCanGenerateStory ? (
                    <button type="button" className="save_button mt-4" onClick={handleGenerateStory} disabled={loading}>{loading ? t('generating') : t('generate_story')}</button>
                  ) : (
                    <button type="button" className="save_button darker_bg w-100 mt-4" onClick={openGenerateStoryModal} disabled={loading}>
                      {loading ? t('generating') : t('generate_story')}
                    </button>
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      )}










      {activeTab === 'profiles' && (
        <div className="hidden_content_outer_container">
          <div className={isProfilesVisible ? 'toggle_for_hidden_container open' : 'toggle_for_hidden_container'} onClick={() => setIsProfilesVisible(!isProfilesVisible)}>
            <h2>{t('saved_profiles')}</h2>

            <span className="subtext_description" dangerouslySetInnerHTML={{ __html: t('profiles_information_subtext') }}></span>
          </div>

          {isProfilesVisible && (
            <div className="mt-3">
              {fetchedProfiles.length > 0 && (
                <div>
                  {fetchedProfiles.map((profile, index) => (
                    <div className="dashboard_results_item no_bg" key={index}>
                      <ProfileText profile={profile} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'profiles' && (
        <>
          {userCanGenerateProfile ? (
            <div className="mt-4">
              <div className="project_form mw-100">
                <label htmlFor="project-name">{t('profile_generation_heading')}</label>

                <span className="dashboard_form_label_subtext">
                  {t('profile_generation_text')}
                </span>

                {loading ? (
                  <div className="loading-progress-bar mt-4">
                    <ProgressBar animated now={progress} label={`${progress}%`} />
                  </div>
                ) : (
                  <button type="button" className="save_button darker_bg w-100 mt-2" onClick={handleGenerateProfiles} disabled={loading}>
                    {loading ? t('generating') : t('generate_profiles')}
                  </button>
                )}
              </div>

              {profileResult && (
                <div ref={profileRef} className="hidden_content_outer_container">
                  <h2 className="mb-3">{t('profile_generated')}</h2>

                  <GeneratedProfileText profile_text={profileResult} />
                </div>
              )}
            </div>
          ) : (
            <div className="mt-4">
              <button type="button" className="save_button darker_bg w-100" onClick={openGenerateProfilesModal} disabled={loading}>
                {loading ? t('generating') : t('generate_profiles')}
              </button>
            </div>
          )}
        </>
      )}

      {activeTab === 'analysis_results' && (
        <div className="hidden_content_outer_container">
          <div className={isAnalysesVisible ? 'toggle_for_hidden_container open' : 'toggle_for_hidden_container'} onClick={() => setIsAnalysesVisible(!isAnalysesVisible)}>
            <h2>{t('saved_analyses_results')}</h2>

            <span className="subtext_description" dangerouslySetInnerHTML={{ __html: t('analyses_information_subtext') }}></span>
          </div>

          {isAnalysesVisible && (
            <div>
              {fetchedAnalyses.length > 0 && (
                <div className="mt-3">
                  {fetchedAnalyses.map((analysis, index) => (
                    <div className="dashboard_results_item no_bg" key={index}>
                      {analysis.image_url && <ImagePreview src={analysis.image_url} />}

                      <AnalysisText analysis_text={analysis.analysis_result} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {activeTab === 'analysis_results' && (
        <>
          {loading ? (
            <div className="loading-progress-bar mt-4">
              <ProgressBar animated now={progress} label={`${progress}%`} />
            </div>
          ) : (
            userCanAnalysisProfile ? (
              <div className="mt-4">
                <ImageUploader 
                  onAnalysisComplete={handleAnalysisComplete} 
                  onSaveComplete={handleSaveComplete} 
                  projectId={projectId}
                />

                {analysisResult && (
                  <div ref={analysisRef} className="hidden_content_outer_container">
                    <h2 className="mb-3">{t('analysis_result')}</h2>

                    {analysisImg && <ImagePreview src={analysisImg} />}

                    <AnalysisText analysis_text={analysisResult} />
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-4">
                <button type="button" className="save_button darker_bg w-100" onClick={openAnalyseProfileModal} disabled={loading}>
                  {loading ? t('analyzing') : t('upload_analyze')}
                </button>
              </div>
            )
          )}
        </>
      )}

      {activeTab === 'ideas' && (
        <>
          {loading ? (
            <div className="loading-progress-bar mt-4">
              <ProgressBar animated now={progress} label={`${progress}%`} />
            </div>
          ) : (
            userCanGenerateTheme ? (
              <div className="mt-4">
                <button type="button" className="save_button darker_bg w-100" onClick={handleGenerateIdeas} disabled={loading}>
                  {loading ? t('generating') : t('generate_themes')}
                </button>
              </div>
            ) : (
              <div className="mt-4">
                <button type="button" className="save_button darker_bg w-100" onClick={openGenerateThemesModal} disabled={loading}>
                  {loading ? t('generating') : t('generate_themes')}
                </button>
              </div>
            )
          )}
        </>
      )}

      <Modal isOpen={isCustomThemeModalOpen} onClose={closeCustomThemeModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_custom_theme')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <Modal isOpen={isCustomScenarioThemeModalOpen} onClose={closeCustomScenarioThemeModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_custom_scenario_theme')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <Modal isOpen={isGenerateProfilesModalOpen} onClose={closeGenerateProfilesModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_profiles')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <Modal isOpen={isGenerateStoryModalOpen} onClose={closeGenerateStoryModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_story')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <Modal isOpen={isAnalyseProfileModalOpen} onClose={closeAnalyseProfileModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_analyses')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <Modal isOpen={isGenerateThemesModalOpen} onClose={closeGenerateThemesModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_themes')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <Modal isOpen={isNoMorePostsModalOpen} onClose={closeNoMorePostsModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_no_more_posts')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      <Modal isOpen={isNoMoreScenariosModalOpen} onClose={closeNoMoreScenariosModal}>
        <h1>{t('modal_heading_plan_needed')}</h1>
        <p>{t('modal_text_no_more_scenarios')}</p>
        <button onClick={handleGoToBilling} className="modal-billing-button">{t('continue_to_payment')}</button>
      </Modal>

      {ideas.length > 0 && (
        <div ref={resultsRef} className="dashboard_results_container">
          <h2>{t('select_theme_to_generate_post')}</h2>

          <div>
            {ideas.map((idea, index) => (
              <div className="dashboard_results_item" key={index} onClick={() => handleIdeaClick(idea)}>
                <p>{idea}</p>
              </div>
            ))}
          </div>
        </div>
      )}

      <Modal isOpen={isIdeaModalOpen} onClose={closeIdeaModal}>
        <div className="custom-modal">
          {showReelOptions ? (
            <>
              <h3>{t('select_reel_settings')}</h3>

              <form className="form_with_black custom_theme_form p-0">
                <label htmlFor="reel_format">{t('select_reel_format')}:</label>

                <select
                  id="reel_format"
                  value={reelFormat}
                  onChange={(e) => setReelFormat(e.target.value)}
                  placeholder={t('select_reel_format')}
                >
                  <option value="" disabled>{t('select_reel_format')}</option>
                  <option value="thematic selection">{t('thematic_selection')}</option>
                  <option value="useful lifehacks">{t('useful_lifehacks')}</option>
                  <option value="educational video">{t('educational_video')}</option>
                  <option value="success story">{t('success_story')}</option>
                  <option value="product demonstration">{t('product_demonstration')}</option>
                  <option value="life stories and funny cases">{t('life_stories_and_funny_cases')}</option>
                  <option value="before after">{t('before_after')}</option>
                  <option value="scenes with characters">{t('scenes_with_characters')}</option>
                  <option value="text on background">{t('text_on_background')}</option>
                  <option value="storytelling">{t('storytelling')}</option>
                  <option value="talking head">{t('talking_head')}</option>
                </select>

                <label htmlFor="reel_cta">{t('select_reel_cta')}:</label>

                <select
                  id="reel_cta"
                  value={reelCta}
                  onChange={(e) => setReelCta(e.target.value)}
                  placeholder={t('select_reel_cta')}
                >
                  <option value="" disabled>{t('select_reel_cta')}</option>
                  <option value="subscribe">{t('subscribe')}</option>
                  <option value="more in highlights">{t('more_in_highlights')}</option>
                  <option value="more useful information in profile">{t('more_useful_information_in_profile')}</option>
                  <option value="write the code word in comments to receive">{t('write_the_code_word_in_comments_to_receive')}</option>
                  <option value="send the code word in direct to receive">{t('send_the_code_word_in_direct_to_receive')}</option>
                </select>

                <label>{t('select_reel_duration')}</label>

                <div className="reel-duration-options">
                  <label className="reel-duration-label">
                    <input
                      type="radio"
                      value={30}
                      checked={reelDuration === 30}
                      onChange={() => setReelDuration(30)}
                    />
                    30 {t('seconds')}
                  </label>
                  <label className="reel-duration-label">
                    <input
                      type="radio"
                      value={60}
                      checked={reelDuration === 60}
                      onChange={() => setReelDuration(60)}
                    />
                    60 {t('seconds')}
                  </label>
                  <label className="reel-duration-label">
                    <input
                      type="radio"
                      value={90}
                      checked={reelDuration === 90}
                      onChange={() => setReelDuration(90)}
                    />
                    90 {t('seconds')}
                  </label>
                </div>
              </form>

              <div className="modal-options">
                <button className="modal-option-button" onClick={handleGenerateReel}>{t('create_reel_scenario')}</button>
              </div>
            </>
          ) : showPostOptions ? (
            <>
              <h3>{t('select_post_settings')}</h3>

              <form className="form_with_black custom_theme_form p-0">
                <label htmlFor="language">{t('select_social')}</label>

                <div className="select_social_options">
                  <label className="select_social_option_item with_background">
                    <input
                      type="checkbox"
                      value="instagram"
                      checked={socialSelect.includes('Instagram')}
                      onChange={() => handleCheckboxChange('Instagram')}
                    />

                    <span className="select_social_option_item_text">
                      <img className="select_social_option_item_img" src={instagramLogo} />

                      <span className="select_social_option_item_text_item">
                        Instagram
                      </span>
                    </span>
                  </label>

                  <label className="select_social_option_item with_background">
                    <input
                      type="checkbox"
                      value="linkedin"
                      checked={socialSelect.includes('Linkedin')}
                      onChange={() => handleCheckboxChange('Linkedin')}
                    />
                    
                    <span className="select_social_option_item_text">
                      <img className="select_social_option_item_img" src={linkedinLogo} />

                      <span className="select_social_option_item_text_item">
                        LinkedIn
                      </span>
                    </span>
                  </label>

                  <label className="select_social_option_item with_background">
                    <input
                      type="checkbox"
                      value="facebook"
                      checked={socialSelect.includes('Facebook')}
                      onChange={() => handleCheckboxChange('Facebook')}
                    />

                    <span className="select_social_option_item_text">
                      <img className="select_social_option_item_img" src={facebookLogo} />

                      <span className="select_social_option_item_text_item">
                        Facebook
                      </span>
                    </span>
                  </label>

                  <label className="select_social_option_item with_background">
                    <input
                      type="checkbox"
                      value="facebook"
                      checked={socialSelect.includes('Telegram')}
                      onChange={() => handleCheckboxChange('Telegram')}
                    />

                    <span className="select_social_option_item_text">
                      <img className="select_social_option_item_img" src={telegramLogo} />

                      <span className="select_social_option_item_text_item">
                        Telegram
                      </span>
                    </span>
                  </label>
                </div>
              </form>

              <div className="modal-options">
                <button className="modal-option-button" onClick={handleGenerateModalPost}>{t('create_post')}</button>
              </div>
            </>
          ) : (
            <>
              <h2>{t('select_content_type')}</h2>
              <div className="modal-options">
                {userCanGeneratePost ? (
                  <button className="modal-option-button" onClick={() => handleIdeaChoice('post')}>{t('create_post')}</button>
                 ) : (
                  <button type="button" className="modal-option-button" onClick={openNoMorePostsModal} disabled={loading}>
                    {loading ? t('generating') : t('generate_post')}
                  </button>
                )}

                {userCanGenerateReel ? (
                  <button className="modal-option-button" onClick={() => handleIdeaChoice('reel')}>{t('create_reel_scenario')}</button>
                 ) : (
                  <button type="button" className="modal-option-button" onClick={openNoMoreScenariosModal} disabled={loading}>
                    {loading ? t('generating') : t('generate_scenario')}
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Project;
